import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Container from 'ui-kit/container';
import Image from 'ui-kit/image';
import Intro from 'ui-kit/intro';
import Section from 'ui-kit/section';
import Stage from 'ui-kit/stage';
import { Headline, P } from 'ui-kit/typography';
import List from 'ui-kit/list';

import Layout from '../components/layout';
import Table1 from '../components/table1';

const Logo = styled.div`
    display: flex;
    justify-content: center;

    & > div {
        display: inline-block;
        max-width: 150px;
        text-align: center;
    }
`;

const BrandsAndProductsPage = ({ data }) => {
    const image1 = data.image1.childImageSharp;
    const image2 = data.image2.childImageSharp;
    const image3 = data.image3.childImageSharp;
    const logoHaltungsform = data.logoHaltungsform.childImageSharp;

    return (
        <Layout description="Haltung">
            <Stage
                image={data.stageImage.childImageSharp}
                tag="Haltung"
                imageAlt="Haltung"
                headline={{ text: 'Artgerecht auf Stroh gehalten.', level: 'h1' }}
                bottomShade
            />
            <Section>
                <Intro title="Das Edeka Premium Schwein: Garant für Premium-Fleisch-Qualität!">
                    <p>
                        Das Strohschwein, der Name sagt es, wird artgerecht auf Stroh gehalten. Mit
                        mindestens 40% mehr Liegefläche als gesetzlich vorgeschrieben. Das Wühlen im
                        Stroh fördert das natürliche Verhalten der Tiere, organische Materialien
                        eröffnen zusätzliche Beschäftigungsmöglichkeiten.
                    </p>
                </Intro>
            </Section>
            <Section bg="gray">
                <Container size="l" gap="xxxxl" grid layout="6-6" cols="2" alignItems="center">
                    <Image
                        {...image1}
                        alt="dummy"
                        layer={1}
                        gap={{ small: 'xxl', medium: 'none' }}
                    />
                    <div>
                        <Headline level="h3">Prima Stallklima!</Headline>
                        <P gap="l">
                            Offene Stallfronten sorgen für Frischluft und Tageslicht zu allen
                            Jahreszeiten.
                        </P>
                        <P>
                            Strohställe mit verschiedenen Bereichen und Klimazonen lassen den
                            Schweinen die freie Wahl, wo sie sich am liebsten aufhalten. Die mit
                            Stroh eingestreuten Liegeflächen und Ausläufe bieten mehr Liegekomfort
                            und reichlich Bewegung. Gerade auch das angeborene Wühlverhalten wird
                            durch die artgerechte Strohhaltung auf natürliche Weise gefördert. Der
                            tägliche Mehraufwand lohnt sich nicht nur für die Tiere, sondern auch
                            für die Verbraucher: sie erhalten eine hochwertige und schmackhafte
                            Fleischqualität.
                        </P>
                    </div>
                </Container>
                <Container size="l" gap="xxxxl" grid layout="6-6" cols="2" alignItems="center">
                    <div>
                        <Headline level="h3">
                            Strohschweine haben ihr Futter zum Fressen gern!
                        </Headline>
                        <P>
                            Selbstverständlich werden die Tiere gentechnikfrei gefüttert. Wobei die
                            Futtermittel vorwiegend von regionalen Lieferanten kommen. Auch in
                            diesem Punkt gibt das Siegel „Geprüfte Qualität Bayern“ wieder eine
                            vertrauenswürdige Auskunft über die Herkunft.
                        </P>
                        <P>
                            Darüber hinaus wird regelmäßig ein Stallklima- und Trinkwassercheck
                            gemäß den Anforderungen der Initiative Tierwohl durchgeführt. Alles im
                            Sinne der Tiere und den hohen Anprüchen von Ihnen - unseren Kunden.
                        </P>
                    </div>
                    <Image
                        {...image2}
                        alt="Strohschweine haben ihr Futter zum Fressen gern!"
                        layer={1}
                        gap={{ small: 'xxl', medium: 'none' }}
                    />
                </Container>
                <Container size="l" gap="xxxxl" grid layout="6-6" cols="2" alignItems="center">
                    <Image
                        {...image3}
                        alt="Wo man sich wohlfühlt, da ist gut Sein!"
                        layer={1}
                        gap={{ small: 'xxl', medium: 'none' }}
                    />
                    <div>
                        <Headline level="h3">Wo man sich wohlfühlt, da ist gut Sein!</Headline>
                        <P>
                            Strohschweine haben es besser als ihre Artgenossen. Stressfrei leben sie
                            in ihrer Gruppe zusammen, können kuscheln und ihren natürlichen
                            Instinkten nachgehen. Aggressives Verhalten im Rudel kommt unter
                            Strohschweinen so gut wie nie vor.
                        </P>
                        <P>
                            Auf dem weichen Stroh bewegen sich die Tiere sicherer, das beugt
                            Verletzungen vor und reduziert den Einsatz von Medikamenten - weitere
                            dicke Pluspunkte, die für das Strohschwein sprechen.
                        </P>
                    </div>
                </Container>
            </Section>
            <Section bg="white">
                <Headline level="h3" textAlign="center">
                    Mehr Platz, mehr Auslauf, mehr Frischluft in einer artgerechten Umgebung:
                </Headline>
                <P textAlign="center">
                    Die erfüllten Kriterien bestätigt das Signet „Haltungsform 3“.
                </P>
                <Container size="l" gap="xxxxl" grid layout="5-2-5" cols="3" alignItems="center">
                    <div />
                    <Logo>
                        <Image
                            {...logoHaltungsform}
                            alt="Haltungsform"
                            layer={1}
                            gap={{ small: 'xxl', medium: 'none' }}
                        />
                    </Logo>
                    <div />
                </Container>
                <Container size="l" gap="xxxxl" alignItems="center">
                    <Headline level="h3" textAlign="center">
                        Pflicht- und Prüfkriterien zur Anerkennung in der Haltungsform 3
                        „Außenklima“ im Überblick
                    </Headline>
                    <P textAlign="center" gap="xxxl">
                        Für das Programm „EDEKA Premium Schwein“ sind weiterhin nachfolgende
                        Prüfkriterien im Bereich Schweinemast zu erfüllen.
                    </P>
                    <Headline level="h4" textAlign="center">
                        Regionalität:
                    </Headline>
                    <P textAlign="center" gap="xxxl">
                        Es besteht eine gültige Zulassung im Bereich Schweinemast für „Qualität und
                        Sicherheit (QS)“.
                        <br />
                        Es besteht eine gültige Zulassung im Bereich Schweinemast für „Geprüfte
                        Qualität – Bayern (GQ)“.
                    </P>
                </Container>
                <Container size="l" gap="xxl" textAlign="center">
                    <Headline level="h3">Haltung – Bodenbeschaffenheit</Headline>
                    <P gap="l">
                        Den Tieren wird mind. 40 % mehr Fläche als gesetzlich vorgeschrieben
                        angeboten.
                    </P>
                </Container>

                <Container size="m" gap="xxxxl" alignItems="top">
                    <Table1 />
                </Container>

                <Container size="m" gap="xxl" alignItems="top" textAlign="center">
                    <div>
                        <Headline level="h3">Stroh, Außenklima, Platzbedarf, Tageslicht</Headline>
                    </div>
                </Container>

                <Container size="l" gap="xxxl" grid layout="6-6" cols="2" alignItems="top">
                    <div>
                        <List
                            showBullets
                            items={[
                                'Durch Offenfrontställe müssen die Tiere jederzeit Außenklimareize (Frischluft und Tageslicht) erfahren können',
                                'Den Tieren wird Stroh als Einstreu und zusätzliches organisches Beschäftigungsmaterial angeboten.',
                                'Der Liegebereich der Mastschweine ist geschlossen und mit Stroh eingestreut.',
                            ]}
                        />
                    </div>
                    <div>
                        <List
                            showBullets
                            items={[
                                'Mind. 50 % der gesetzlich vorgeschriebenen Mindestbodenfläche ist mit Stroh eingestreut.',
                                'Die Einstreu muss mit trockenem, sauberem und schimmelfreiem Stroh erfolgen. Zusätze zur Feuchtigkeitsbindung z.B. Gesteinsmehl sind erlaubt.',
                                'Die Einstreumenge muss so bemessen sein, dass der Liegebereich der Tiere ausreichend mit strohbedeckt ist.',
                            ]}
                        />
                    </div>
                </Container>
            </Section>

            <Section bg="gray">
                <Container size="m" alignItems="center">
                    <Headline level="h3" textAlign="center">
                        Fütterung
                    </Headline>
                    <P textAlign="center" gap="xxxl">
                        Der Anteil an regionalen Futtermitteln beträgt ca. 60 %.
                        <br />
                        Die Tiere werden gentechnikfrei gefüttert.
                        <br />
                        Der Betrieb ist nach dem VLOG-Standard zertifiziert.
                        <br />
                        Die Mindestfütterungszeit „ohne-Gentechnik“ von mindestens 4 Monaten wird
                        bei jedem Mastschwein das zur Schlachtung abgegeben wird eingehalten
                    </P>
                    <Headline level="h3" textAlign="center">
                        Tiergesundheit und Tierwohl
                    </Headline>
                    <P textAlign="center" gap="xxxl">
                        Teilnahme am QS-Salmonellenmonitoring
                        <br />
                        Teilnahme am QS-Antibiotikamonitoring
                        <br />
                        Teilnahme am indexierten Schlachtbefunddatenprogramm: Die
                        Befunddatenerfassung am Schlachthof erfolgt gemäß QS-Leitfaden Befunddaten
                        in der Schweineschlachtung.
                        <br />
                        Die Transportdauer ab der letzten Beladung bis zum Schlachthof beträgt max.
                        vier Stunden.
                        <br />
                        Der Stallklima- und Tränkewassercheck wird regelmäßig gemäß den
                        Anforderungen der Initiative Tierwohl durchgeführt.
                        <br />
                    </P>
                </Container>
            </Section>
        </Layout>
    );
};

BrandsAndProductsPage.propTypes = {
    data: PropTypes.shape({
        stageImage: PropTypes.object,
        image1: PropTypes.object,
        image2: PropTypes.object,
        image3: PropTypes.object,
        logoHaltungsform: PropTypes.object,
    }).isRequired,
};

export const query = graphql`
    query {
        stageImage: file(relativePath: { eq: "haltung/stage.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        image1: file(relativePath: { eq: "haltung/image1.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 640, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        image2: file(relativePath: { eq: "haltung/image2.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 640, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        image3: file(relativePath: { eq: "haltung/image3.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 640, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        logoHaltungsform: file(relativePath: { eq: "haltungsform3.png" }) {
            childImageSharp {
                fluid(maxWidth: 640, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;

export default BrandsAndProductsPage;
